import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Container, Typography, Link, Box } from '@material-ui/core';
import { SitePage } from 'components/layout';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginTop: theme.spacing(8),
    },
    title: {
      marginBottom: theme.spacing(4),
    },
  }),
);

export default function index() {
  const classes = useStyles();

  return (
    <SitePage>
      <Container className={classes.root}>
        <Box mb={4}>
          <Typography color="primary" variant="h4" align="left" className={classes.title}>
            Contact Us
          </Typography>

          <Typography variant="body1" paragraph>
            We welcome any questions, comments, or feedback about the <em>Partners in Parenting</em> program or research
            project.
          </Typography>

          <Typography variant="body1" paragraph>
            You can contact us at:
          </Typography>

          <Typography variant="body1" paragraph>
            Email: <Link href="mailto: med-pip-plus@monash.edu">med-pip-plus@monash.edu</Link>
          </Typography>

          <Typography variant="body1" paragraph>
            Phone: <Link href="tel:0399051250">(03) 9905 1250</Link>
          </Typography>
        </Box>

        <Box mb={4}>
          <Typography color="primary" variant="h4" align="left" className={classes.title}>
            Marketing Materials
          </Typography>

          <Typography variant="body1" paragraph>
            If you would like to promote the Partners in Parenting program to your network, we have a number of
            marketing materials available.
          </Typography>

          <Typography variant="body1" paragraph>
            Please request access to our PiP Promotion Pack by emailing us at:{' '}
            <Link href="mailto: med-pip-plus@monash.edu">med-pip-plus@monash.edu</Link>
          </Typography>
        </Box>
      </Container>
    </SitePage>
  );
}
